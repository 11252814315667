<template>
	<router-view />
</template>

<script>
import DocumentTemplateModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentTemplateModuleGuard'

const CHILDREN_ROUTES = ['', '-editor']

export default {
	name: 'DocumentTemplatesView',
	mixins: [DocumentTemplateModuleGuard],
	created: function () {
		// redirect on default route of list if route is not complete
		const currentRouteName = this.$route.name
		if (
			(() => {
				let a = true
				CHILDREN_ROUTES.forEach(route => {
					if (currentRouteName.includes(route)) {
						a = false
					}
				})

				return a
			})()
		) {
			this.appService.goTo(`${currentRouteName}`, false)
		}
	}
}
</script>
